/* eslint-disable prefer-template */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */

import { makeVar } from '@apollo/client';
import axios from 'axios';
import { differenceInDays, formatDistanceToNowStrict } from 'date-fns';
import { toast } from 'react-toastify';
import { SongDefaultState } from '../player/types';
import { toastErrorObj, toastSuccessObj, valuesObj } from './objects';

export const replacedGlossaryWords = makeVar([]);

export const formatTags = (tags) => {
  if (tags?.length < 1 || !tags) return 'N/A';
  return tags.map((tag, index) => {
    if (index + 1 !== tags.length) return `${tag.formattedName} / `;
    return tag.formattedName;
  });
};

// eslint-disable-next-line max-len
export const formatSongDuration = (duration) =>
  (Math.round((duration / 60000) * 100) / 100).toFixed(2);

export const formatList = (text) => {
  if (!text) return null;

  let newText = text;

  newText = newText
    .replace(/<ol>/gi, '<ol class="list__colored__ordered text_black mb-4">')
    .replace(/<ul>/gi, '<ul class="list__colored text_black mb-4">')
    .replace(/<li>/gi, '<li><div>')
    .replace(/<\/li>/gi, '</div></li>');

  return newText;
};

export const removeHtmlClassFromString = (text) => {
  if (!text) return null;
  return text
    .replace(/class="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi, '')
    .replace(/<li >/gi, '<li>')
    .replace(/<ul >/gi, '<ul>')
    .replace(/<music>/gi, '<span class="pagnifin">')
    .replace(/<music >/gi, '<span class="pagnifin">')
    .replace(/<\/music>/gi, '</span>')
    .replace(/<timesig/gi, '<span class="time-sig" ')
    .replace(/<timesig /gi, '<span class="time-sig" ')
    .replace(/<\/timesig>/gi, '</span>');
};

export const formatTime = (time) => {
  const date = new Date(0);
  date.setSeconds(time || 0);
  return date.toISOString().substr(14, 5);
};

export const returnNumberRange = (start, end) => {
  const numArr = [];
  for (let i = start; i <= end; i += 1) {
    numArr.push(i);
  }
  return numArr;
};

export const shortenLongDescription = (text, limit) => {
  if (text?.length > limit) {
    return `${text.substr(0, limit - 4)}...`;
  }
  return text;
};

export const capitalizeFirstLetter = (str) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stripHtml = (text) => {
  if (!text) return null;
  const div = document.createElement('DIV');
  div.innerHTML = text;
  return div.textContent || div.innerText || '';
};

export const getVimeoVideoPadding = async (url) => {
  try {
    const {
      data: { width, height },
    } = await axios.get(`https://vimeo.com/api/oembed.json?url=${url}`);
    return `${((height / width) * 100).toFixed(2)}% 0 0 0`;
  } catch (e) {
    return null;
  }
};

export const addGlossaries = (text, glossaries) => {
  if (!text) return null;
  if (glossaries?.length < 1) return text;

  let textStr = text;
  for (let i = 0; i < glossaries.length; i++) {
    const { word, definition } = glossaries[i];

    // match standalone word or when it ends with ., comma, ;, and )
    // but not when it has double backticks `` appended
    const glossaryRegEx = new RegExp(
      '\\b(' + word + ')(?=[]\\b|\\s|$|\\.|<|,|;|\\))(?!,``|;``|\\.``|\\)``)',
      'i'
    );

    /**
     * Split multiword words and replace space with +++
     * so individual words dont get matched in the rest of the iterations
     *
     * @param {string} wordStr
     * @returns string
     */
    const formatForMultiWord = (wordStr) => {
      const wordToArr = wordStr.split(' ');
      if (wordToArr.length === 1) return wordStr;

      return wordToArr.join('+++').concat('≠≠');
    };

    /**
     * add double backticks to the end of each word
     * in the definition so it doesn't get matched in the rest of the iterations
     * replace < with ææ so in case a glossary word is the last in the definition
     *
     * @returns string
     */
    const formatDefinition = () => {
      return definition
        .split(' ')
        .join('`` ')
        .concat('``')
        .replaceAll('<', 'ææ');
    };

    textStr = textStr.replace(glossaryRegEx, (match) => {
      const trimmedMatch = match.trim();
      return `<span data-toggle="tooltip"
      data-placement="top"
      data-html="true"
      title="<div class='tooltip-inner__header'>${formatForMultiWord(
        word
      )}</div> ${formatDefinition()}"><span class="tooltip-inner__text">${formatForMultiWord(
        trimmedMatch
      )}</span></span>`;
    });
  }

  // remove `` and ≠≠, replace ææ with < and +++ with space
  return textStr
    .replaceAll('``', '')
    .replaceAll('ææ', '<')
    .replaceAll('+++', ' ')
    .replaceAll('≠≠', '')
    .replace(/class="pagnifin-font*"/gi, '')
    .replace(/class="normal-text*"/gi, '')
    .replace(/<music>/gi, "<span class='pagnifin'>")
    .replace(/<music >/gi, "<span class='pagnifin'>")
    .replace(/<normal>/gi, "<span class='normal-text'>")
    .replace(/<normal >/gi, "<span class='normal-text'>")
    .replace(/<\/music>/gi, '</span>')
    .replace(/<\/normal>/gi, '</span>');
};

export const mediaPlayerSelectedSongStateDefault = {
  loading: true,
  data: {
    song: {
      ...SongDefaultState,
    },
  },
};

export const getSongAudioSrcs = (audios) =>
  audios.map((audio) => audio.files.mp3);

export const albumArtDefault = '/img/artwork-missing.jpg';

export const groupBy = (array, f) => {
  const groups = {};
  array.forEach((o) => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
};

export const returnHasTags = (key, type, name, randomise = false) => {
  return {
    [key]: {
      AND: [
        { column: 'TYPE', value: type },
        { column: 'NAME', value: name },
      ],
    },
    available: true,
    randomise,
  };
};

export const buildTags = (data) => {
  const tagsArr = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    if (
      !['min_age', 'max_age'].includes(key) &&
      value !== 'all' &&
      key !== 'composers'
    ) {
      tagsArr.push({ type: valuesObj[key], formatted_name: value });
    }
  }
  return tagsArr;
};

export const buildFilterParams = (data, contentType = '') => {
  const filteredArr = buildTags(data);

  const filterParameters = {
    ...(filteredArr.length > 0 && {
      filtered: filteredArr,
    }),
  };

  if ('min_age' in data && 'max_age' in data) {
    filterParameters.hasSongDetail = {
      OR: [
        {
          AND: [
            { column: 'MINAGE', value: data.min_age ?? 5, operator: 'LTE' },
            { column: 'MAXAGE', value: data.max_age ?? 11, operator: 'GTE' },
          ],
        },
        {
          AND: [
            { column: 'MINAGE', value: data.max_age ?? 5, operator: 'LTE' },
            { column: 'MAXAGE', value: data.min_age ?? 11, operator: 'GTE' },
          ],
        },
      ],
    };
  }

  if (contentType !== '') {
    let categoryFilter = {
      AND: [
        {
          column: 'NAME',
          value: contentType,
        },
      ],
    };

    if (contentType === 'Blog or Guide') {
      categoryFilter = {
        OR: [
          {
            column: 'NAME',
            value: 'Blog',
          },
          {
            column: 'NAME',
            value: 'Guide',
          },
        ],
      };
    }

    if (contentType === 'Curriculum links and more') {
      categoryFilter = {
        OR: [
          {
            column: 'NAME',
            value: 'Curriculum Link',
          },
          {
            column: 'NAME',
            value: 'Creative Ideas',
          },
          {
            column: 'NAME',
            value: 'Bright Idea',
          },
          {
            column: 'NAME',
            value: 'Spotlight on...',
          },
        ],
      };
    }

    filterParameters.hasCategory = categoryFilter;
  }

  if ('composers' in data && data.composers !== 'all') {
    filterParameters.where = {
      column: 'COMPOSERS',
      value: data.composers,
    };
  }

  return filterParameters;
};

export const extractContent = (html) => {
  return new DOMParser().parseFromString(html, 'text/html').documentElement
    .textContent;
};

export const displayLoadingMessage = (message = 'Loading...', options = {}) =>
  toast.info(message, { toastId: 'loading', ...options });

export const dismissLoadingMessage = () => toast.dismiss('loading');

export const displaySuccessMessage = (message = 'Success!') =>
  toast.success(message, toastSuccessObj);

export const displayErrorMessage = (message = 'Operation failed!') =>
  toast.error(message, toastErrorObj);

export const dismissErrorMessage = () => toast.dismiss('error');

export const displayWarningMessage = (message = 'Warning!') =>
  toast(`${message} Click here to close this message`, {
    toastId: 'warning',
  });

export const dismissWarningMessage = () => toast.dismiss('warning');

/**
 *
 * @param {number} inputMinAge
 * @param {number} inputMaxAge
 * @param {number} resourceMinAge
 * @param {number} resourceMaxAge
 * @returns {boolean}
 */
export const matchesAgeRange = (
  inputMinAge,
  inputMaxAge,
  resourceMinAge,
  resourceMaxAge
) =>
  (resourceMinAge <= inputMinAge && resourceMaxAge >= inputMaxAge) ||
  (resourceMinAge <= inputMaxAge && resourceMaxAge >= inputMinAge);

export const redirectToOota = () =>
  window.open('https://outoftheark.co.uk', '_blank');

export const equals = (a, b) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

export const calculateDaysLeftInSubscription = (dateRenewal, isActive) => {
  let daysLeftOnSubscription = '0 days';

  if (dateRenewal && isActive) {
    daysLeftOnSubscription = formatDistanceToNowStrict(
      new Date(parseInt(dateRenewal, 10) * 1000),
      { roundingMethod: 'floor' }
    );
  } else if (!dateRenewal) {
    return 'N/A';
  }

  return daysLeftOnSubscription;
};

export const calculateDaysLeftInTrial = (date) => {
  return differenceInDays(new Date(parseInt(date, 10) * 1000), new Date());
};

export const getSubscriptionCardTexts = (
  subs,
  daysLeftOnSubscription,
  activeTrial
) => {
  const activeSubs = subs?.filter((sub) => sub.isActive);

  const paidSubs = activeSubs?.filter(
    (sub) => sub.subscriptionType === 'STRIPE'
  );

  const hasMultiplePaidSubs = paidSubs?.length > 1;

  const manualSubs = activeSubs?.filter(
    (sub) => sub.subscriptionType === 'MANUAL'
  );

  const hasManualSubs = manualSubs?.length > 0;

  const getBottomText = () => {
    if (activeTrial) return 'remaining in your free trial subscription';
    if (hasMultiplePaidSubs) return 'active subscriptions';
    if (manualSubs?.length > 0) return ' ';
    return false;
  };

  let middleText = hasMultiplePaidSubs
    ? activeSubs.length
    : daysLeftOnSubscription;

  let topText = '';
  if (hasManualSubs) {
    middleText = 'Custom subscription';
    topText = ' ';
  }

  return {
    topText,
    middleText,
    bottomText: getBottomText(),
    hasMultiplePaidSubs,
    hasManualSubs,
  };
};

export const splitByThreshold = (sentence, threshold) => {
  if (!sentence || threshold <= 0 || threshold > sentence.length) {
    return [sentence];
  }

  let splitIndex = sentence.lastIndexOf(' ', threshold);

  if (splitIndex === -1) {
    splitIndex = sentence.indexOf(' ', threshold);
  }

  if (splitIndex === -1) {
    return [sentence];
  }

  const part1 = sentence.slice(0, splitIndex).trim();
  const part2 = sentence.slice(splitIndex).trim();

  return [part1, part2];
};
