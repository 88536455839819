/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import Cookies from 'universal-cookie';

export const valuesObj = {
  song_type: 'Song Type',
  seasonal: 'Seasonal',
  style: 'Style',
  speed: 'Speed',
  vocal_range: 'Vocal Range',
  curriculum_area: 'Curriculum Subject Links',
  topic_or_theme: 'Topic/Theme',
  values: 'Values (PSHE, SMSC)',
  curriculum_concept: 'Curriculum Concept',
  curriculum_subject_links: 'Curriculum Subject Links',
  routines: 'routines',
  vocal_range_description: 'Vocal Range Description',
  musical_style: 'Musical Style',
  tempo_description: 'Tempo Description',
};

export const assemblyThemesObj = {
  ANIMATED: 'Animated Assemblies',
  COLLECTIVE_WORSHIP: 'Collective Worship Assemblies',
  CHRISTMAS: 'Christmas Assemblies',
  EASTER: 'Easter Assemblies',
  HARVEST: 'Harvest Assemblies',
  SUMMER: 'Summer Assemblies',
  AUTUMN: 'Autumn Assemblies',
  SPRING: 'Spring Assemblies',
  WINTER: 'Winter Assemblies',
};

export const typeObj = {
  engage: 'bg__fuchsia',
  wonder: 'bg__orange',
  resource: 'bg__bush-lilac',
  resourceQuestions: 'bg__apple',
  reflect: 'bg__blue-navy',
  prayer: 'bg__lime',
  goDeeper: 'bg__cerise-dark',
  sing: 'bg__blue-pacific',
  customSection: 'bg__mandy',
  activity: 'bg__cerise-dark',
  brightIdea: 'bg__christi',
  talkAbout: 'bg__green-meadow',
  bibleBit: 'bg__cerise-dark',
  respond: 'bg__orange',
  bibleBitOrRespond: 'bg__cerise-dark',
  bibleBitOrReflect: 'bg__cerise-dark',
  engagementActivity: 'bg__fuchsia',
  talkOrThinkAbout: 'bg__green-meadow',
  responsiveActivity: 'bg__orange',
  prayerActivity: 'bg__lime',
  playAnimation: 'bg__blue-navy',
  readStory: 'bg__bush-lilac',
  creativeIdeas: 'bg__yellow-sun',
  vocalPathwayActivity: 'bg__mandy',
  spotlightOn: 'bg__green-meadow',
  curriculumLink: 'bg__mantis',
  musicCurriculumActivity: 'bg__blue-curious',
};

export const stepColorObj = {
  1: 'bg__lime',
  2: 'bg__pumpkin',
  3: 'bg__cerise-dark',
  4: 'bg__amethyst',
};

export const eventColourObject = {
  1: 'bg__vivid-orange',
  2: 'bg__moderate-blue',
  3: 'bg__cerise-dark',
  4: 'bg__moderate-green',
  5: 'bg__jellybean',
  6: 'bg__fuchsia',
};

export const updateQuery = {
  updateQuery: (prev, newQuery) =>
    newQuery.fetchMoreResult ? newQuery.fetchMoreResult : prev,
};

export const defaultFilterValues = {
  topic_or_theme: 'all',
  seasonal: 'all',
  values: 'all',
  min_age: 5,
  max_age: 11,
  test: 'all',
  curriculum_subject_links: 'all',
  curriculum_concept: 'all',
  routines: 'all',
  vocal_range_description: 'all',
  song_type: 'all',
  musical_style: 'all',
  tempo_description: 'all',
  style: 'all',
  speed: 'all',
  vocal_range: 'all',
  resource_type: 'all',
  curriculum_area: 'all',
  content_type: 'all',
};

export const modelMap = {
  SONG: 'songs',
  RESOURCE: 'resources',
  ASSEMBLY: 'assemblies',
  PLAYLIST: 'playlists',
};

export const playlistDefaultObj = { name: '' };
export const collectionDefaultObj = {
  title: '',
  description: '',
};
export const toastSuccessObj = {
  toastId: 'success',
  autoClose: 4000,
};
export const toastErrorObj = { toastId: 'error' };

const loginUrl = process.env.REACT_APP_OOTA_LOGIN_URL;
const cookies = new Cookies();
const cookieYes = cookies.get('cookieyes-consent');
export const sparkyardLoginUrl = cookieYes
  ? (() => {
      const cookieValues = cookieYes.split(',').reduce((acc, curr) => {
        const [key, value] = curr.split(':');
        acc[key] = value;
        return acc;
      }, {});

      console.log(cookieValues)

      const params = [];
      if (cookieValues.advertisement === 'yes') params.push('syproceed_ad=yes');
      if (cookieValues.analytics === 'yes') params.push('syproceed_an=yes');
      if (cookieValues.functional === 'yes') params.push('syproceed_fu=yes');

      return `${loginUrl}?${params.join('&')}`;
    })()
  : loginUrl;
export const resetPasswordUrl = process.env.REACT_APP_PASSWORD_RESET_URL;

export const categoryMap = [
  {
    name: 'Bright Idea',
    image: '/img/bright-idea.png',
  },
  {
    name: 'Spotlight on...',
    image: '/img/spotlight.png',
  },
  {
    name: 'Music Curriculum Activity',
    image: '/img/music-curriculum-activity.png',
  },
  {
    name: 'Curriculum Link',
    image: '/img/curriculum-activity.png',
  },
  {
    name: 'Music Activity',
    image: '/img/Resource-thumbnail_MusicActivity.png',
  },
  {
    name: 'Blog',
    image: '/img/blog.png',
  },
  {
    name: 'Guide',
    image: '/img/guide.png',
  },
  {
    name: 'SEND Resource',
    image: '/img/send-resource.png',
  },
  {
    name: 'Creative Ideas',
    image: '/img/Resource-thumbnail_Creative.png',
  },
  {
    name: 'Song Calendar Activity',
    image: '/img/Resource-thumbnail_Calendar-resources.png',
  },
];

export const defaultAssemblyThumbnail = '/img/assembly-plan.png';
export const defaultResourceThumbnail =
  '/img/ResourceCategories_Resources-Guide-44.png';

export const resourceQuery = {
  hasTags: {
    OR: [
      {
        AND: [
          {
            column: 'TYPE',
            value: 'OOTA Singing School',
          },
          {
            column: 'NAME',
            value: 'Curriculum',
          },
        ],
      },
      {
        column: 'TYPE',
        value: 'Topic/Theme',
      },
      {
        column: 'TYPE',
        value: 'Curriculum Concept',
      },
      {
        column: 'TYPE',
        value: 'Curriculum Subject Links',
      },
    ],
  },
};

export const curriculumLinksAndMoreItems = [
  'Curriculum Link',
  'Creative Ideas',
  'Spotlight on...',
  'Bright Idea',
  'Song Calendar Activity',
];

export const blogOrGuideItems = ['Blog', 'Guide'];

export const filterTypeMap = {
  'Blog or Guide': (resourceType) => blogOrGuideItems.includes(resourceType),
  'Curriculum links and more': (resourceType) =>
    curriculumLinksAndMoreItems.includes(resourceType),
  all: () => true,
};

export const defaultAvatarMid = '/img/user.svg';
export const defaultAvatarLg = '/img/big-avatar.png';
export const defaultOrganisationLogo = '/img/organisation_default.png';

export const availableRoles = [
  'Teacher/T.A',
  'Organization/School Head',
  'Music Co-ordinator',
  'Visiting Music Teacher',
  'Church Leader/Minister',
  'Worship Leader',
  "Children's/Youth Leader",
  'Parent/Carer',
  'Purchaser/Administrator',
  'Other',
];
