import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { user } from '../../utils/reactiveVars';
import {
  clearLoginPreferences,
  removeUserFromLocalStorage,
} from '../../utils/user';

const Logout = () => {
  useEffect(() => {
    const loginUrl = process.env.REACT_APP_OOTA_LOGIN_URL;
    const cookies = new Cookies();
    const cookieYes = cookies.get('cookieyes-consent');
    const sparkyardLoginUrl = cookieYes
      ? (() => {
          const cookieValues = cookieYes.split(',').reduce((acc, curr) => {
            const [key, value] = curr.split(':');
            acc[key] = value;
            return acc;
          }, {});

          const params = [];
          if (cookieValues.advertisement === 'yes') {
            params.push('syproceed_ad=yes');
          }
          if (cookieValues.analytics === 'yes') {
            params.push('syproceed_an=yes');
          }
          if (cookieValues.functional === 'yes') {
            params.push('syproceed_fu=yes');
          }

          return `${loginUrl}?${params.join('&')}`;
        })()
      : loginUrl;
    user();
    removeUserFromLocalStorage();
    clearLoginPreferences();
    window.location.href = sparkyardLoginUrl;
  }, []);

  return <h1>You have been logged out</h1>;
};

export default Logout;
