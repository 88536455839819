import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-symbol';

import 'mobx-react-lite/batchingForReactDom';

import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import cssVars from 'css-vars-ponyfill';
import Player from './player/Index';
import './styles/js/loader';
import 'bootstrap/dist/js/bootstrap.bundle';
import './styles/app.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import Routes from './routes';
import client from './graphql/config';
import { StoreProvider } from './player/stores/RootStore';
import ScrollToTop from './components/Shared/ScrollToTop';
import ActionsModalWrapper from './components/Shared/Others/ActionsModalWrapper';
import { CookieBanner } from './components/CookieBanner/CookieBanner';
import 'swiper/swiper.min.css'
import TagManager from 'react-gtm-module'

cssVars({
  // Fixes ie11 missing vars issues for rgb colours when using twin.macro
  variables: {
    '--bg-opacity': '1',
    '--text-opacity': '1',
    '--placeholder-opacity': '1',
    '--divide-opacity': '1',
    '--border-opacity': '1',
    '--space-y-reverse': '1',
    '--space-x-reverse': '1',
    '--divide-x-reverse': '1',
    '--divide-y-reverse': '1',
    '--transform-skew-x': '1',
    '--transform-skew-y': '1',
  },
});


const tagManagerArgs = {
  gtmId: 'GTM-WBCQK86',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ToastContainer autoClose={false} position="top-center" />
      <StoreProvider>
        <Router>
          <ScrollToTop />
          <ActionsModalWrapper />
          <Routes />
          <Player />
          {/*<CookieBanner />*/}
        </Router>
      </StoreProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
